<template>
    <div>
        <div v-if="showForm">
            <media-form
                v-show="!loading"
                :loading="loading"
                :original-data="data"
                :original-video-info="videoInfo"
                :original-information="information"
                :original-gallery="gallery"
                @submit="attemptSubmit($event)"
                @startLoader="loading=true"
                @loadData="loadData"
            ></media-form>
        </div>

        <md-snackbar
            md-position="center"
            :md-duration="Infinity"
            :md-active.sync="showSnackbar"
            md-persistent
        >
            <span>{{ snackMsg }}</span>
            <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
    </div>
</template>

<script lang="javascript">

    import config from '../../config.js'

    import {mapActions} from 'vuex'
    import {mapGetters} from 'vuex'


    import MediaForm from "./Forms/MediaForm";



    export default{

        name: 'Media',
        components: {
          MediaForm
        },
        data(){
            return {
                config: config,
                data: null,
                information: {},
                videoInfo: {},
                gallery: null,

                showDialog: false,
                snackMsg: '',
                showSnackbar: false,
                loading: false,
                showForm: false,
                formChanged: false,
                pendingUpdates: false
            }
        },
        computed: {
            ...mapGetters({

            }),

        },

        watch: {
            '$route.params.facilityId'(newId, oldId) {
                if(newId !== oldId){
                    this.loadData()
                }
            },
            loading(newVal, oldVal) {
                this.$emit('loading', newVal);
            }
        },

        async created(){
            await this.loadData();
        },

        methods: {
            ...mapActions([
                'fetchFacilityInformation',
                'fetchFacilityDataChildNames',
                'updateFacilityInformation',
                'updateAdminItem',
                'createAdminItem',
                'deleteAdminItem',
                "searchCities",
                'getStates',
                "saveFacilityImage",
                'deleteContentUpdate',
                'loadFacilityMediaPageInfo',
                'updateFacilityMediaPage',
            ]),

            async loadData(){
                this.showForm = false;
                // console.log('LOADING MEDIA')
                this.loading = true;
                this.facilityId = this.$route.params.facilityId

                const {data, information, videoInfo, pendingUpdates, gallery} = await this.loadFacilityMediaPageInfo(this.facilityId);
                this.data = data;
                this.information = information;
                this.videoInfo = videoInfo;
                this.pendingUpdates = pendingUpdates;
                this.gallery = gallery
              // console.log('GALLERY LOADED', JSON.parse(JSON.stringify(gallery)))

                this.showForm = true;
                this.loading = false
                //console.log('DONE LOADING MEDIA PAGE.');

                // SHOW PENDING UPDATES SNACK
                // if(pendingUpdates){
                //     this.snackMsg = "Your updates are pending review. They will go live once they have been reviewed."
                //     this.showSnackbar = true;
                // }


                // remind md-tabs to resize so the bottom of the form isn't cut off.
                window.setTimeout(() => {
                  window.dispatchEvent(new Event("resize"));
                }, 1000);
            },

            getValidationClass (fieldName) {
                const field = this.$v.information[fieldName]
                if (field) {
                    return {
                        'md-invalid': field.$invalid && field.$dirty
                    }
                }
            },


            async attemptSubmit({submitObject, submitFields}){
                try{
                    await this.updateFacilityMediaPage({
                        facilityId: this.facilityId,
                        submitObject,
                        submitFields
                    })


                    await this.loadData()

                    this.snackMsg = "Your updates are pending review. They will go live once they have been reviewed."
                    this.showSnackbar = true;

                }catch (e) {
                    console.error(e)
                    if(e.response && e.response.data && e.response.data.message){
                        this.snackMsg = e.response.data.message
                    }else {
                        this.snackMsg = "Something went wrong. Please contact support."
                    }
                    this.showSnackbar = true;
                }
            },
        }
    }

</script>

<style lang="scss" scoped>

        .md-title{
            display: inline-flex;
        }


        .md-app-content {       
            margin-left: 0px;
            margin-right: 0px;
            padding-left: 0px;
            padding-top: 0px;
            padding: 0px;
        }
        .md-tab {
            padding: 0px;
        }

        .md-tabs-navigation, .md-tab{
            margin-bottom: 0px;
            padding: 0px;
        }
       

</style>

<style>
    #media-tab .video-error{
        top: 67px;
        left: 176px;
    }
</style>